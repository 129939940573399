import { logger } from 'core/logger';
import store from 'core/store';
import { queryClient } from 'api/client';
import {
  userLoadingStarted,
  userLoggedin,
  userLoginFailed,
  userLoggedout,
  userSwitchedProfile,
} from 'core/store/auth';
import { UserManager, WebStorageStateStore } from 'oidc-client';
import { config } from '../config';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { appInsights } from 'core/logger/ApplicationInsightsService';
import {
  PublicClientApplication,
  EventType,
  AccountInfo,
  SilentRequest
} from "@azure/msal-browser";
import { msalConfig, loginRequest } from "../../../src/core/config";

export const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.initialize().then(() => {
  // Default to using the first account if no account is active on page load
  if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  }
  // Optional - This will update account state if a user signs in from another tab or window
  msalInstance.enableAccountStorageEvents();

  msalInstance.addEventCallback(async (event: any) => {
    if (event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
      || event.eventType === EventType.SSO_SILENT_SUCCESS
    ) {
      const account = event.payload;
      msalInstance.setActiveAccount(account as AccountInfo);
      const userAccount = msalInstance.getActiveAccount();
      const request: SilentRequest = {
        ...loginRequest,
        account: userAccount!,
      };
  
      try {
        // Silently acquires an access token which is then attached to a request for API access
        const response = await msalInstance.acquireTokenSilent(request);
        store.dispatch(
          userLoggedin({
            accessToken: response.accessToken,
            name: userAccount?.idTokenClaims?.name,
            //email: userAccount?.idTokenClaims?.emails![0],
            email: userAccount?.idTokenClaims?.email
          })
        );
      } catch (e) {
        console.log(e);
      }
    }
  });
});
const userManager = new UserManager({
  authority: config.authority,
  client_id: config.clientId,
  redirect_uri: config.redirectUri,
  response_type: config.responseType,
  scope: config.scope,
  post_logout_redirect_uri: config.postLogoutRedirectUri,
  stateStore: new WebStorageStateStore({ store: window.localStorage }),
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  automaticSilentRenew: false,
  silent_redirect_uri: config.silentSigninUri,
});

export const localStorageSelectedProfileKey = 'selectedProfileId';

export async function loadUserFromStorage() {
  try {
    store.dispatch(userLoadingStarted());
    
    //const user = await userManager.getUser();
    if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
      // Account selection logic is app dependent. Adjust as needed for different use cases.
      msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
    }

    const user = msalInstance.getActiveAccount();
    if (!user) {
      return store.dispatch(userLoginFailed());
    }
    let accessToken = store.getState().auth.user?.accessToken;
    //acquiring access token if not exists in store
    if(!accessToken){
      try {
        const userAccount = user;
        const request: SilentRequest = {
          ...loginRequest,
          account: userAccount!,
        };
        const response = await msalInstance.acquireTokenSilent(request);
        accessToken = response.accessToken;
      } catch (error) {
        console.log(error);
      }
    }
    store.dispatch(
      userLoggedin({
        accessToken: accessToken,
        name: user.idTokenClaims?.name,
        email: user.idTokenClaims?.email
      })
    );
    const profileId = localStorage.getItem(localStorageSelectedProfileKey);
    if (profileId) {
      switchUserProfile(Number(profileId));
    }
  } catch (e) {
    logger?.error(`UserService -> User not found: ${e}`);
    appInsights?.trackException({ error: new Error(`UserService -> User not found: ${e}`), severityLevel: SeverityLevel.Error });
    store.dispatch(userLoginFailed());
  }
}

export function clearUserManagerUserState() {
  userManager.clearStaleState();
  userManager.removeUser();
  localStorage.removeItem(localStorageSelectedProfileKey);
}

export function signinRedirect() {
  store.dispatch(userLoadingStarted());
  return userManager.signinRedirect();
}

export function signinRedirectCallback() {
  return userManager.signinRedirectCallback();
}

export function silentSigninCallback() {
  return userManager.signinSilentCallback();
}

export function signoutRedirectCallback() {
  clearUserManagerUserState();
  return userManager.signoutRedirectCallback();
}
 
export async function signout() {
  store.dispatch(userLoggedout());
  queryClient.clear();
  clearLocalStorage();
  const logoutRequest = {
    account: msalInstance.getActiveAccount(),
    postLogoutRedirectUri: "/",
  };
  return   msalInstance.logoutRedirect(logoutRequest);
  //return userManager.signoutRedirect();
}

export function switchUserProfile(profileId: number | undefined) {
  store.dispatch(userSwitchedProfile(Number(profileId)));
  if (profileId)
    localStorage.setItem(
      localStorageSelectedProfileKey,
      profileId?.toString()
    );
}
export function clearLocalStorage() {
  localStorage.removeItem(localStorageSelectedProfileKey);
}

export default userManager;
