import { signinRedirect } from 'core/auth/userService';
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { useEffect } from "react";
import { ROUTES } from 'core/routes';
import store, { useAppSelector } from 'core/store';
import { Navigate } from 'react-router-dom';
import { loginRequest } from 'core/config';

export function Login() {
  localStorage.removeItem('logout-event');
  const { instance, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const user = useAppSelector((state) => state.auth.user);

  const doLogin = async () => {
    if (!user && !isAuthenticated && inProgress === InteractionStatus.None) {
      await instance.loginRedirect(loginRequest).catch((e: any) => {
        console.log("Exception happened during login redirect: ", e);
      });
    }
  }
 
  useEffect(() => {
    doLogin();
  }, [instance, isAuthenticated, inProgress]);

  return user ? <Navigate to={ROUTES.redirectHandler} /> : null;
}
