import { LogLevelDesc, LogLevelNumbers } from 'loglevel';
import { LogLevel } from "@azure/msal-browser";
type AllowedLogLevel = Exclude<LogLevelDesc, LogLevelNumbers>;
type LogLevelObjectType = { [key in AllowedLogLevel]: string };

const logLevelObject: LogLevelObjectType = {
  trace: '',
  debug: '',
  info: '',
  warn: '',
  error: '',
  silent: '',
  TRACE: '',
  DEBUG: '',
  INFO: '',
  WARN: '',
  ERROR: '',
  SILENT: '',
};

const isAssignable = (type: string): type is AllowedLogLevel => {
  return type in logLevelObject;
};

const logLevelValue =
  process.env.NODE_ENV === 'test'
    ? 'SILENT'
    : process.env.REACT_APP_DATADOG_LOG_LEVEL;

if (!logLevelValue || !isAssignable(logLevelValue)) {
  throw new Error(
    `Provided logLevel: ${logLevelValue} is not supported. Allowed values are: ${Object.keys(
      logLevelObject
    ).join(', ')}`
  );
}

export const config = {
  clientApiUrl: process.env.REACT_APP_ACTIVEWEB_API_URL,
  connectionsAdminApiUrl: process.env.REACT_APP_CA_API_URL,
  authority: process.env.REACT_APP_AUTH_AUTHORITY,
  clientId: process.env.REACT_APP_AUTH_CLIENT_ID,
  redirectUri: process.env.REACT_APP_AUTH_REDIRECT_URI,
  silentSigninUri: process.env.REACT_APP_AUTH_SILENT_SIGNIN_URI,
  responseType: process.env.REACT_APP_AUTH_RESPONSE_TYPE,
  scope: process.env.REACT_APP_AUTH_SCOPE,
  postLogoutRedirectUri: process.env.REACT_APP_AUTH_POST_LOGOUT_REDIRECT_URI,
  loggingEnvironment: process.env.REACT_APP_LOGGING_ENVIRONMENT,
  datadogApplicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
  datadogClientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
  datadogSite: process.env.REACT_APP_DATADOG_SITE,
  datadogService: process.env.REACT_APP_DATADOG_SERVICE,
  datadogLogLevel: logLevelValue,
  useDataDogLogs: process.env.REACT_APP_USE_DATADOG_LOGS?.toString(),
  appInsightsConnectionString: process.env.REACT_APP_INSIGHTS_CONNECTION_STRING,
  useAppInsightsConnectionLogs: process.env.REACT_APP_USE_INSIGHTS_CONNECTION_LOGS?.toString(),
  appInsightsDDService: process.env.REACT_APP_INSIGHTS_DD_SERVICE,
  allRepairsFilterValue: 12,
  totalIdleTime: process.env.REACT_APP_IDLE_TIME || 15,
  popupLogoutTime: process.env.REACT_APP_POPUP_LOGOUT_TIME || 2,
  showMock:
    process.env.REACT_APP_LOGGING_ENVIRONMENT == 'dev' ||
    process.env.REACT_APP_LOGGING_ENVIRONMENT == 'stg'
      ? process.env.REACT_APP_SHOW_MOCK
      : false,
};

//// Msal Configuration


// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const firefox = ua.indexOf("Firefox");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

export const b2cPolicies = {
    names: {
        signUpSignIn: 'b2c_1a_refinish_aw_signup_signin_phoneoremailmfa_dev',
        forgotPassword: 'B2C_1_reset_v3',
        editProfile: 'B2C_1A_B2C_AW_1A_PROFILEEDIT',
        impersonate: 'B2C_1A_Refinish_AW_Impersonation_DEV'
    },
    authorities: {
        signUpSignIn: {
            authority: 'https://ppgindustriesincb2cdev.b2clogin.com/ppgindustriesincb2cdev.onmicrosoft.com/B2C_1A_REFINISH_AW_SIGNUP_SIGNIN_PHONEOREMAILMFA_DEV',
        },
        forgotPassword: {
            authority: 'https://fabrikamb2c.b2clogin.com/fabrikamb2c.onmicrosoft.com/B2C_1_reset_v3',
        },
        editProfile: {
            authority: 'https://ppgindustriesincb2cdev.b2clogin.com/ppgindustriesincb2cdev.onmicrosoft.com/B2C_1A_AW_REFINISH_AW_PROFILEEDIT_DEV',
        },
        impersonate: {
            authority: 'https://ppgindustriesincb2cdev.b2clogin.com/ppgindustriesincb2cdev.onmicrosoft.com/B2C_1A_Refinish_AW_Impersonation_DEV',
            scopes: ['https://ppgindustriesincb2cdev.onmicrosoft.com/40f1c1fa-bf2b-41de-8c53-4eed280dc34c/adminapi'],
            extraQueryParameters: { 
                targetEmail: 'null'
            }
        },  
    },
    authorityDomain: 'ppgindustriesincb2cdev.b2clogin.com',
};

// Config object to be passed to Msal on creation
export const msalConfig = {
    auth: {
        clientId: '60244239-d143-466d-9a83-9452c55ed021', // This is the ONLY mandatory field that you need to supply.
        authority: b2cPolicies.authorities.signUpSignIn.authority, // Choose SUSI as your default authority.
        knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
        redirectUri: '/', // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
        postLogoutRedirectUri: '/', // Indicates the page to navigate after logout.
        //navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.

    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: isIE || isEdge || isFirefox
    },
    system: {
        allowNativeBroker: false, // Disables WAM Broker
        loggerOptions: {
            loggerCallback: (level: number, message: string, containsPii: boolean) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        //appInsights?.trackEvent({ name: message });
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        //appInsights?.trackEvent({ name: message });
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        //appInsights?.trackEvent({ name: message });
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        //appInsights?.trackEvent({ name: message });
                        return;
                    default:
                        return;
                }
            }
        }
    }
};

/**
 * Enter here the coordinates of your web API and scopes for access token request
 * The current application coordinates were pre-registered in a B2C tenant.
 */
export const apiConfig = {
    scopes: ['https://ppgindustriesincb2cdev.onmicrosoft.com/40f1c1fa-bf2b-41de-8c53-4eed280dc34c/adminapi'],
    uri: 'https://clientapi-dev.activewebonline.com/external/DEV-activeweb-clientapi'
};

// Scopes you add here will be prompted for consent during login
export const loginRequest = {
    scopes: [...apiConfig.scopes],
};