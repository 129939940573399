import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface User {
  accessToken?: string;
  name?: string;
  email?: string | unknown;
  activeUserProfileId?: number;
}

export interface AuthState {
  user?: User;
  isLoading: boolean;
}

const initialAuthState: AuthState = {
  isLoading: true,
};

const slice = createSlice({
  name: 'auth',
  initialState: initialAuthState,
  reducers: {
    userLoggedin: (state, action: PayloadAction<User>) => {
      return {
        ...state,
        isLoading: false,
        user: action.payload,
      };
    },
    userLoginFailed: (state) => {
      return { ...state, user: undefined, isLoading: false };
    },
    userLoggedout: (state) => {
      return { ...state, user: undefined, isLoading: false };
    },
    userLoadingStarted: (state) => {
      return { ...state, isLoading: true };
    },
    userAccessTokenExpired: (state) => {
      return { ...state, user: undefined, isLoading: false };
    },
    userSwitchedProfile: (state, action: PayloadAction<number>) => {
      state.user = { ...state.user, activeUserProfileId: action.payload };
    },
  },
});

export default slice.reducer;

export const {
  userLoggedin,
  userLoginFailed,
  userLoggedout,
  userLoadingStarted,
  userAccessTokenExpired,
  userSwitchedProfile,
} = slice.actions;
